var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { UsersCollection, ImagesCollection, CountriesCollection, TimezonesCollection, BusinessSubscriptionsCollection, BusinessAddonsCollection, CalendarsCollection, TodosListsCollection, BusinessesCollection, } from "@bundles/UIAppBundle/collections";
let BusinessEditForm = class BusinessEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.businesses.fields.name"),
                name: ["name"],
                component: Ant.Input,
            },
            {
                id: "address",
                label: t("management.businesses.fields.address"),
                name: ["address"],
                component: Ant.Input,
            },
            {
                id: "latitude",
                label: t("management.businesses.fields.latitude"),
                name: ["latitude"],
                component: Ant.InputNumber,
            },
            {
                id: "longitude",
                label: t("management.businesses.fields.longitude"),
                name: ["longitude"],
                component: Ant.InputNumber,
            },
            {
                id: "description",
                label: t("management.businesses.fields.description"),
                name: ["description"],
                component: Ant.Input,
            },
            {
                id: "phoneNumber",
                label: t("management.businesses.fields.phoneNumber"),
                name: ["phoneNumber"],
                component: Ant.Input,
            },
            {
                id: "socialMediaLinks",
                label: t("management.businesses.fields.socialMediaLinks"),
                name: ["socialMediaLinks"],
                nest: [
                    {
                        id: "facebook",
                        label: t("management.businesses.fields.socialMediaLinks.facebook"),
                        name: ["socialMediaLinks", "facebook"],
                        component: Ant.Input,
                    },
                    {
                        id: "twitter",
                        label: t("management.businesses.fields.socialMediaLinks.twitter"),
                        name: ["socialMediaLinks", "twitter"],
                        component: Ant.Input,
                    },
                    {
                        id: "linkedin",
                        label: t("management.businesses.fields.socialMediaLinks.linkedin"),
                        name: ["socialMediaLinks", "linkedin"],
                        component: Ant.Input,
                    },
                    {
                        id: "instagram",
                        label: t("management.businesses.fields.socialMediaLinks.instagram"),
                        name: ["socialMediaLinks", "instagram"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "accountPaused",
                label: t("management.businesses.fields.accountPaused"),
                name: ["accountPaused"],
                initialValue: false,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "isHold",
                label: t("management.businesses.fields.isHold"),
                name: ["isHold"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "numberOfCredits",
                label: t("management.businesses.fields.numberOfCredits"),
                name: ["numberOfCredits"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "leadCredits",
                label: t("management.businesses.fields.leadCredits"),
                name: ["leadCredits"],
                required: true,
                initialValue: 0,
                component: Ant.InputNumber,
            },
            {
                id: "stripeCustomerId",
                label: t("management.businesses.fields.stripeCustomerId"),
                name: ["stripeCustomerId"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "isOnboarded",
                label: t("management.businesses.fields.isOnboarded"),
                name: ["isOnboarded"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "ownerId",
                label: t("management.businesses.fields.owner"),
                name: ["ownerId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", required: true }, void 0) }), void 0)),
            },
            {
                id: "logoId",
                label: t("management.businesses.fields.logo"),
                name: ["logoId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", required: false }, void 0) }), void 0)),
            },
            {
                id: "countryId",
                label: t("management.businesses.fields.country"),
                name: ["countryId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: CountriesCollection, field: "_id", required: false }, void 0) }), void 0)),
            },
            {
                id: "timezoneId",
                label: t("management.businesses.fields.timezone"),
                name: ["timezoneId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TimezonesCollection, field: "_id", required: false }, void 0) }), void 0)),
            },
            {
                id: "subscriptionId",
                label: t("management.businesses.fields.subscription"),
                name: ["subscriptionId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessSubscriptionsCollection, field: "stripeSubscriptionId", required: false }, void 0) }), void 0)),
            },
            {
                id: "addonIds",
                label: t("management.businesses.fields.addon"),
                name: ["addonIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessAddonsCollection, field: "stripeAddonSubscriptionId", required: false, mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "calendarId",
                label: t("management.businesses.fields.calendar"),
                name: ["calendarId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: CalendarsCollection, field: "_id", required: true }, void 0) }), void 0)),
            },
            {
                id: "todosListId",
                label: t("management.businesses.fields.todosList"),
                name: ["todosListId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TodosListsCollection, field: "_id", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            address: 1,
            latitude: 1,
            longitude: 1,
            description: 1,
            phoneNumber: 1,
            socialMediaLinks: {
                facebook: 1,
                twitter: 1,
                linkedin: 1,
                instagram: 1,
            },
            accountPaused: 1,
            isHold: 1,
            numberOfCredits: 1,
            leadCredits: 1,
            stripeCustomerId: 1,
            isOnboarded: 1,
            owner: {
                _id: 1,
                fullName: 1,
            },
            ownerId: 1,
            logo: {
                _id: 1,
            },
            logoId: 1,
            country: {
                _id: 1,
            },
            countryId: 1,
            timezone: {
                _id: 1,
            },
            timezoneId: 1,
            subscription: {
                _id: 1,
                stripeSubscriptionId: 1,
            },
            subscriptionId: 1,
            addon: {
                _id: 1,
                stripeAddonSubscriptionId: 1,
            },
            addonIds: 1,
            calendar: {
                _id: 1,
            },
            calendarId: 1,
            todosList: {
                _id: 1,
            },
            todosListId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.businesses.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => BusinessesCollection),
    __metadata("design:type", typeof (_a = typeof BusinessesCollection !== "undefined" && BusinessesCollection) === "function" ? _a : Object)
], BusinessEditForm.prototype, "collection", void 0);
BusinessEditForm = __decorate([
    Service({ transient: true })
], BusinessEditForm);
export { BusinessEditForm };
